import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import "../assets/css/main.css";
import Cta from "../components/cta";
import {
  NewspaperIcon,
  PhoneIcon,
  SupportIcon,
} from "@heroicons/react/outline";

const supportLinks = [
  {
    name: "Sales",
    href: "#",
    description:
      "Varius facilisi mauris sed sit. Non sed et duis dui leo, vulputate id malesuada non. Cras aliquet purus dui laoreet diam sed lacus, fames.",
    icon: PhoneIcon,
  },
  {
    name: "Technical Support",
    href: "#",
    description:
      "Varius facilisi mauris sed sit. Non sed et duis dui leo, vulputate id malesuada non. Cras aliquet purus dui laoreet diam sed lacus, fames.",
    icon: SupportIcon,
  },
  {
    name: "Media Inquiries",
    href: "#",
    description:
      "Varius facilisi mauris sed sit. Non sed et duis dui leo, vulputate id malesuada non. Cras aliquet purus dui laoreet diam sed lacus, fames.",
    icon: NewspaperIcon,
  },
];

const AboutPage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout seo={data.strapiAbout.seo}>
      <div class="relative bg-white">
        <div class="lg:absolute lg:inset-0">
          <div class="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
            <img
              class="h-56 w-full object-cover lg:absolute lg:h-full"
              src="https://images.unsplash.com/photo-1521737711867-e3b97375f902?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
              alt=""
            />
          </div>
        </div>
        <div class="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div class="lg:col-start-2 lg:pl-8">
            <div class="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              About Us
            </h3>
              <div class="mt-5 prose prose-indigo text-gray-500">
                <p>
                  We do aim to focus on providing customer-centric,
                  result-oriented, cost-competitive IT & software solutions to
                  our valuable global clients. We do provide high-performing,
                  on-demand teams of developers as well.
                </p>
                <p>
                  We are highly recognized among our clients for our agile
                  development process, killer web designs, apps that are meant
                  to knock out your competitors, SLA-driven approach, and
                  on-time project delivery.
                </p>
                <p>
                  Our vision is to be a well-established software consulting &
                  development company to serve the ISVs, Agencies, SMEs and
                  Enterprises. Constant innovation is our main key for achieving
                  the ultimate goal of success. We have emerged as a globally
                  recognized software development company by providing superior
                  quality solutions.
                </p>
                <p>
                  Our core values are the foundation of the company – they’ve
                  been there from the start and really are the DNA of
                  organization. They represent the way we behave towards each
                  other, our colleagues and our customers, and drive our
                  approach to work and business.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

<div class="bg-white">
  <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
    <div>
      <p class="mt-2 text-3xl font-extrabold text-gray-900">Our Core Values</p>
      <p class="mt-4 text-lg text-gray-500">It's not just a code everytime.</p>
    </div>
    <div class="mt-12 lg:mt-0 lg:col-span-2">
      <dl class="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-3 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Authenticity</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">
            Be sincere, honest & open in dealings to ensure trustworthy. Never lie - fulfill commitments. We also keep a transparent system as far as the prices for services are concerned.
          </dd>
        </div>



        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Leadership</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">
Set trends for your peers and the industry in general to follow. We follow the latest industry trends.          </dd>
        </div>

        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Quest for perfection</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">
Improve and innovate continuously. Pursue excellence in everything that you do.          </dd>
        </div>
          <div className="relative">
              <dt>
                  <svg className="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none"
                       viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                  </svg>
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-900">Offering Exceptional Value</p>
              </dt>
              <dd className="mt-2 ml-9 text-base text-gray-500">
                  Understand and exceed customer expectations, all the time. Maximum client content is our top priority.
                  If there is one thing we never negotiate on that is our quality.
              </dd>
          </div>
        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Respect</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">
Treat others as you would like yourself to be treated. We ensure maximum trust to our customers.
          </dd>
        </div>

        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Employee Satisfaction</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">
            Our greatest asset. We love and support our colleagues and operate without hierarchy.
          </dd>
        </div>
      </dl>
    </div>
  </div>
</div>
      <Cta />

    </Layout>
  );
};

const query = graphql`
  query {
    strapiAbout {
      seo {
        metaTitle
        metaDescription
        shareImage {
          publicURL
        }
      }
    }
  }
`;

export default AboutPage;
